import React, { useState, useRef, useCallback, useMemo, useEffect, memo } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db, functions } from '../authConfig.ts';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Paper,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { styled } from '@mui/system';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Navigate } from 'react-router-dom';
import OrgChartComponent from '../components/OrgChartComponent';
import ExportButton from '../components/ExportButton';
import { createCustomerPortalSession } from '../services/stripeService.ts';
import Settings from '../components/Settings';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { checkFeatureAccess, isFeatureEnabled } from '../utils/featureFlags';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import FilterButton from '../components/FilterButton';
import { useAuth } from '../contexts/AuthContext';

// Add these styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginRight: '10px',
  color: theme.palette.common.white,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
  padding: theme.spacing(1, 3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[8],
  },
}));

const WelcomeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius * 4,
  padding: theme.spacing(1, 2),
  marginRight: theme.spacing(2),
}));

const IconButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: 0,
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '8px 24px',
  textTransform: 'none',
  fontWeight: 600,
  minWidth: '120px',
  transition: 'all 0.2s ease',
  '&.filter-btn': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  '&.upload-btn': {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    }
  },
  '&.export-btn': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
}));

const Dashboard = memo(() => {
  const { user, setUser } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [displayFields, setDisplayFields] = useState({
    name: true,
    title: true,
    department: true,
    hireDate: true
  });
  const [orgChartData, setOrgChartData] = useState(null);
  const orgChartRef = useRef(null);
  const navigate = useNavigate();
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.log('No authenticated user, redirecting to login');
          navigate('/login');
          return;
        }

        // Verify token is still valid
        await currentUser.getIdToken(true);
      } catch (error) {
        console.error('Auth verification failed:', error);
        navigate('/login');
      }
    };

    // Only check auth if user state is null
    if (!user) {
      checkAuth();
    }
  }, [user, navigate]);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, [navigate]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFieldToggle = (field) => {
    setDisplayFields(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleManageSubscription = useCallback(async () => {
    setIsSubscriptionDialogOpen(true);
  }, []);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setIsSubscriptionDialogOpen(false);
  }, []);

  const handleConfirmSubscriptionManagement = useCallback(async () => {
    try {
      if (!user.uid) {
        throw new Error("User ID not found");
      }

      // Get user data from Firestore first
      const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
      const userData = userDoc.data();

      if (!userData?.stripeCustomerId) {
        throw new Error("No Stripe Customer ID found. Please contact support.");
      }

      const portalUrl = await createCustomerPortalSession(user.uid);
      if (portalUrl) {
        window.open(portalUrl, '_blank');
      } else {
        throw new Error("Failed to create portal session");
      }
    } catch (error) {
      console.error("Error opening Stripe Customer Portal:", error);
      // You might want to show this error to the user via a toast or alert
    } finally {
      setIsSubscriptionDialogOpen(false);
    }
  }, [user.uid]);

  const orgChartProps = useMemo(() => ({
    user,
    setUser,
    displayFields,
    setOrgChartData,
    orgChartData,
  }), [user, setUser, displayFields, setOrgChartData, orgChartData]);

  console.log('User object in Dashboard:', user);

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const handleUploadClick = () => {
    setShowUploadDialog(true);
  };

  const handleUploadClose = () => {
    setShowUploadDialog(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);

          // Transform the data to match your org chart structure
          const transformedData = jsonData.map(row => ({
            id: row.id?.toString(),
            name: row.name,
            title: row.title,
            managerId: row.managerId?.toString(),
            department: row.department,
            tags: row.tags ? JSON.parse(row.tags) : [],
            hireDate: row.hireDate,
            // Add any other fields you need
          }));

          // Update your org chart data
          setOrgChartData(transformedData);
          
          // Close the dialog
          setShowUploadDialog(false);
          
          // Reset the file input
          event.target.value = null;
          
          // Optional: Show success message
          // You might want to add a toast/snackbar notification here
        } catch (error) {
          console.error('Error parsing Excel file:', error);
          // Optional: Show error message to user
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error uploading file:', error);
      // Optional: Show error message to user
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  // Conditionally log only important state changes
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && user) {
      console.log('User state updated in Dashboard:', {
        uid: user.uid,
        premium: user.premium
      });
    }
  }, [user]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="static">
        <Toolbar>
          <AccountTreeIcon sx={{ mr: 2 }} />
          <LogoText variant="h6" component="div" sx={{ flexGrow: 1 }}>
            OCG
          </LogoText>
          <WelcomeBox>
            <PersonIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              Welcome, {user?.displayName || user?.name || 'User'}
            </Typography>
          </WelcomeBox>
          <IconButton color="inherit" onClick={handleOpenSettings}>
            <SettingsIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleSignOut}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      <Container maxWidth={false} sx={{ mt: 4, px: 4, height: 'calc(100vh - 64px)', overflow: 'auto' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" component="h1" fontWeight="bold">
            Organization Chart
          </Typography>
          <Box 
            display="flex" 
            alignItems="center"
            sx={{ gap: 1 }}
          >
            <FilterButton 
              displayFields={displayFields}
              handleFieldToggle={handleFieldToggle}
              anchorEl={anchorEl}
              handleFilterClick={handleFilterClick}
              handleFilterClose={handleFilterClose}
            />
            
            <ActionButton
              className="upload-btn"
              startIcon={<UploadFileIcon />}
              onClick={handleUploadClick}
            >
              Upload Backup
            </ActionButton>
            
            <ExportButton
              contentRef={orgChartRef}
              fileName="organization_chart"
            />
          </Box>
        </Box>
        <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
          <OrgChartComponent 
            ref={orgChartRef}
            {...orgChartProps}
          />
        </StyledPaper>
        <Dialog
          open={isSubscriptionDialogOpen}
          onClose={handleCloseSubscriptionDialog}
        >
          <DialogTitle>Manage Subscription</DialogTitle>
          <DialogContent>
            You will be redirected to the Stripe Customer Portal to manage your subscription. Do you want to continue?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscriptionDialog}>Cancel</Button>
            <Button onClick={handleConfirmSubscriptionManagement} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showUploadDialog} onClose={handleUploadClose}>
          <DialogTitle>Upload Backup</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Upload a previously exported Excel backup file to restore your org chart data.
              This will overwrite your current data.
            </DialogContentText>
            <input
              type="file"
              ref={fileInputRef}
              accept=".xlsx,.xls"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleFileSelect}
                startIcon={<UploadFileIcon />}
              >
                Select File
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUploadClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Settings 
          user={user} 
          setUser={setUser} 
          open={isSettingsOpen} 
          onClose={handleCloseSettings} 
        />
      </Container>
    </Box>
  );
});

Dashboard.displayName = 'Dashboard';

export default Dashboard;
