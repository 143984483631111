import { msalInstance } from '../msalConfig';  // Adjust this import based on your MSAL configuration

export const isTokenExpired = (token) => {
  if (!token) return true;
  
  // Decode the token (note: this is safe as we're not using this for security purposes)
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) return true;
  
  const payload = JSON.parse(atob(tokenParts[1]));
  const expirationTime = payload.exp * 1000; // Convert to milliseconds
  
  return Date.now() >= expirationTime;
};

const ensureActiveAccount = async () => {
  let account = msalInstance.getActiveAccount();
  if (!account) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
      account = accounts[0];
    } else {
      throw new Error('No accounts found. User may need to sign in again.');
    }
  }
  return account;
};

export const refreshAccessToken = async (user) => {
  if (!user) {
    throw new Error('User object is undefined');
  }

  if (!user.provider) {
    throw new Error('User provider is not specified');
  }

  switch (user.provider) {
    case 'microsoft.com':
      // Refresh Microsoft token
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw new Error('No active account! Verify a user is signed in and setActiveAccount has been called.');
      }
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account
      });
      return response.accessToken;
    case 'google.com':
      // Refresh Google token
      // Implement Google token refresh logic here
      throw new Error('Google token refresh not implemented');
    default:
      throw new Error(`Unsupported provider: ${user.provider}`);
  }
};
