import React, { useState, useEffect } from 'react';
import { Typography, Paper, Box, Button, CircularProgress, Modal, List, ListItem, ListItemIcon, ListItemText, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/system';
import { auth, db, functions } from '../authConfig.ts';
import { getUserData } from '../services/databaseService.ts';
import { signOut } from 'firebase/auth';
import { createCustomerPortalSession } from '../services/stripeService.ts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DeleteIcon from '@mui/icons-material/Delete';
import { httpsCallable } from 'firebase/functions';
import { updateProfile } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { alpha } from '@mui/material/styles';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';

const PowerfulTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

const AccentTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Roboto Slab', serif",
  color: theme.palette.text.secondary,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  maxHeight: '90vh',
  overflowY: 'auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 4,
  padding: theme.spacing(1, 3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1), // Reduced from theme.spacing(2)
  fontWeight: 600,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const WarningAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.contrastText,
}));

const WarningButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  borderRadius: theme.shape.borderRadius,
  padding: '6px 10px',
  marginBottom: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

const StatusPill = styled('span')(({ theme, status }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '16px',
  fontSize: '0.875rem',
  fontWeight: 500,
  backgroundColor: 
    status === 'active' ? alpha(theme.palette.success.main, 0.1) :
    status === 'canceled' ? alpha(theme.palette.error.main, 0.1) :
    status === 'past_due' ? alpha(theme.palette.warning.main, 0.1) :
    alpha(theme.palette.grey[500], 0.1),
  color:
    status === 'active' ? theme.palette.success.main :
    status === 'canceled' ? theme.palette.error.main :
    status === 'past_due' ? theme.palette.warning.main :
    theme.palette.grey[500],
}));

function Settings({ user, setUser, open, onClose }) {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [deleteExpanded, setDeleteExpanded] = useState(false);

  useEffect(() => {
    let mounted = true;
    let authCheckTimeout = null;

    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (!mounted) return;

      authCheckTimeout = setTimeout(async () => {
        try {
          if (currentUser) {
            const freshData = await getUserData(currentUser.uid);
            if (mounted) {
              setUserData(freshData);
            }
          }
        } catch (error) {
          console.error('Settings auth state error:', error);
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      }, 100);
    });

    return () => {
      mounted = false;
      if (authCheckTimeout) clearTimeout(authCheckTimeout);
      unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      onClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      if (!user || !user.uid) {
        console.error('User or user ID is not available');
        alert('Unable to manage subscription. Please try logging in again.');
        return;
      }

      // Get fresh user data to ensure we have the latest Stripe customer ID
      const userData = await getUserData(user.uid);
      if (!userData?.stripeCustomerId) {
        console.error('No Stripe Customer ID found for user:', user.uid);
        alert('No active subscription found. Please subscribe first or contact support if you believe this is an error.');
        return;
      }

      // Ensure the customer ID is a clean string without quotes
      const cleanCustomerId = userData.stripeCustomerId.replace(/['"]+/g, '');
      console.log('Creating portal session for customer:', cleanCustomerId);
      
      const portalUrl = await createCustomerPortalSession(user.uid);
      
      if (!portalUrl) {
        console.error('Portal URL creation failed for customer:', cleanCustomerId);
        throw new Error('Failed to create portal session');
      }

      window.open(portalUrl, '_blank');
    } catch (error) {
      console.error("Error opening Stripe Customer Portal:", error);
      const errorMessage = error.message || 'Unknown error';
      alert(`Unable to open subscription management page: ${errorMessage}. Please try again later or contact support.`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = () => {
    if (window.confirm(
      "Are you absolutely sure you want to delete all your data? This action cannot be undone.\n\n" +
      "Please note: This will only delete your data. It will not cancel your subscription " +
      "or stop any recurring charges. You must manage your subscription separately."
    )) {
      handleDeleteAllData();
    }
  };

  const handleDeleteAllData = async () => {
    if (window.confirm("Are you sure you want to delete all your data? This action cannot be undone.")) {
      try {
        setLoading(true);
        const deleteAllDataFunction = httpsCallable(functions, 'deleteAllUserData');
        await deleteAllDataFunction({ userId: user.uid });
        
        // Refresh the user's token to get the updated custom claims
        await auth.currentUser.getIdToken(true);
        
        // Fetch the updated user data
        const updatedUserData = await getUserData(user.uid);
        setUserData(updatedUserData);
        
        // Update the user state in the parent component
        setUser(prevUser => ({ ...prevUser, ...updatedUserData }));
        
        alert("All data has been deleted successfully.");
      } catch (error) {
        console.error("Error deleting data:", error);
        alert("An error occurred while deleting data. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
    >
      <StyledPaper>
        <CloseButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </CloseButton>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <PowerfulTypography variant="h4" gutterBottom id="settings-modal-title">
              Account Settings
            </PowerfulTypography>
            <AccentTypography variant="body1" paragraph>
              Manage your account and subscription
            </AccentTypography>
            
            <SectionContainer>
              <SectionTitle variant="h6">Personal Information</SectionTitle>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <AccountCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Name" 
                    secondary={userData?.displayName || user?.displayName || 'N/A'} 
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Email" 
                    secondary={userData?.email || user?.email || 'N/A'} 
                  />
                </ListItem>
              </List>
            </SectionContainer>

            <SectionContainer>
              <SectionTitle variant="h6">Subscription</SectionTitle>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <SubscriptionsIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Subscription Status" 
                    secondary={
                      <Box component="span">
                        <StatusPill status={userData?.subscriptionStatus || 'inactive'}>
                          {userData?.subscriptionStatus === 'active' ? 'Active' :
                           userData?.subscriptionStatus === 'canceled' ? 'Canceled' :
                           userData?.subscriptionStatus === 'past_due' ? 'Past Due' :
                           'Inactive'}
                        </StatusPill>
                      </Box>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WorkspacePremiumIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Current Plan" 
                    secondary={userData?.subscriptionPlan || 'Free Plan'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <UpdateIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Billing Cycle"
                    secondary={userData?.subscriptionPeriod ? 
                      `${userData.subscriptionPeriod.charAt(0).toUpperCase()}${userData.subscriptionPeriod.slice(1)}ly` : 
                      'N/A'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CalendarTodayIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Next Billing Date" 
                    secondary={userData?.nextBillingDate ? 
                      new Date(userData.nextBillingDate).toLocaleDateString() : 
                      'N/A'}
                  />
                </ListItem>
              </List>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleManageSubscription}
                startIcon={<CreditCardIcon />}
                disabled={loading || !userData?.stripeCustomerId}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Manage Subscription'}
              </StyledButton>
              {!userData?.stripeCustomerId && (
                <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                  No active subscription found. Please contact support if you believe this is an error.
                </Typography>
              )}
            </SectionContainer>

            <SectionContainer>
              <SectionTitle variant="h6">Account Actions</SectionTitle>
              <WarningAccordion
                expanded={deleteExpanded}
                onChange={() => setDeleteExpanded(!deleteExpanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="delete-content"
                  id="delete-header"
                >
                  <Typography><WarningIcon sx={{ mr: 1 }} />Delete All Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography paragraph>
                    Warning: Deleting your data is irreversible. This action will remove all your personal information and content from our systems.
                  </Typography>
                  <Typography paragraph>
                    Please note: This will only delete your data. It will not cancel your subscription or stop any recurring charges. You must manage your subscription separately.
                  </Typography>
                  <WarningButton
                    variant="contained"
                    onClick={handleDeleteConfirm}
                    startIcon={<DeleteIcon />}
                  >
                    Confirm Data Deletion
                  </WarningButton>
                </AccordionDetails>
              </WarningAccordion>
            </SectionContainer>
          </>
        )}
      </StyledPaper>
    </Modal>
  );
}

export default Settings;
