import React from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Checkbox, styled } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

// Styled components
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '8px 24px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    marginTop: '8px',
    minWidth: 180,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      padding: '8px',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: '8px',
  padding: '10px 12px',
  margin: '4px 0',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '42px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '& .MuiListItemText-primary': {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

const FilterButton = ({ displayFields, handleFieldToggle, anchorEl, handleFilterClick, handleFilterClose }) => {
  return (
    <>
      <StyledButton
        variant="contained"
        onClick={handleFilterClick}
        startIcon={<FilterListIcon />}
        color="primary"
      >
        Filter
      </StyledButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.entries(displayFields).map(([field, checked]) => (
          <StyledMenuItem key={field} onClick={() => handleFieldToggle(field)}>
            <ListItemIcon>
              <Checkbox 
                checked={checked}
                color="primary"
                sx={{ padding: '0' }}
              />
            </ListItemIcon>
            <ListItemText>
              {field.charAt(0).toUpperCase() + field.slice(1)}
            </ListItemText>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default FilterButton; 