import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Button, 
  Box, 
  Typography, 
  Paper, 
  Container, 
  Alert, 
  CircularProgress, 
  Snackbar 
} from '@mui/material';
import { styled } from '@mui/system';
import { msalInstance } from '../msalConfig';
import { authenticateWithFirebase, saveUserData } from '../services/databaseService.ts';
import { signInWithPopup } from 'firebase/auth';
import { 
  auth, 
  googleProvider, 
  initializeAuth 
} from '../authConfig.ts';
import OrgChartIcon from '@mui/icons-material/AccountTree';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, setDoc, serverTimestamp, getDoc, updateDoc, getFirestore, deleteField } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

// Add db constant at the top level of the file
const db = getFirestore();

// SVG logos for Google and Microsoft
const GoogleLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" />
    <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" />
    <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" />
    <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" />
    <path fill="none" d="M1 1h22v22H1z" />
  </svg>
);

const MicrosoftLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" width="23" height="23">
    <path fill="#f3f3f3" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </svg>
);

const PageContainer = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
});

const HeroSection = styled(Box)(({ theme }) => ({
  flex: 1,
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  color: theme.palette.common.white,
  padding: theme.spacing(10, 0),
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LoginContainer = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
}));

const LoginButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  textTransform: 'none',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5, 2),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    boxShadow: theme.shadows[2],
  },
}));

function Login() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [gmailError, setGmailError] = useState(() => {
    return localStorage.getItem('gmailError') || '';
  });

  // Set persistence when component mounts
  useEffect(() => {
    const setupPersistence = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (error) {
        console.error('Error setting persistence:', error);
      }
    };
    setupPersistence();
  }, []);

  // Check for error parameter in URL on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorMessage = params.get('error');
    if (errorMessage) {
      setError(decodeURIComponent(errorMessage));
      setOpenSnackbar(true);
      // Clean up the URL
      navigate('/login', { replace: true });
    }
  }, [location]);

  const handleLoginError = (error) => {
    console.error('Login error:', error);
    // Show a user-friendly error message
    setError('An error occurred during login. Please try again.');
  };

  // Update handleSnackbarClose
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    localStorage.removeItem('gmailError');
  };

  const handleLoginSuccess = async (firebaseUser) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      const userData = userDoc.exists() ? userDoc.data() : {};
      
      const enrichedUser = {
        ...firebaseUser,
        ...userData,
      };

      setUser(enrichedUser);
      localStorage.setItem('user', JSON.stringify(enrichedUser));
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const handleGoogleLogin = async () => {
    if (loginInProgress) return;
    setLoginInProgress(true);
    setError(null);
    
    try {
      // Initialize auth first
      await initializeAuth();
      
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const email = result.user?.email;
      
      if (!email) {
        throw new Error('No email provided from Google login');
      }

      if (email.toLowerCase().endsWith('@gmail.com')) {
        const errorMessage = 'Personal Gmail accounts are not supported. Please use a Google Workspace account.';
        localStorage.setItem('gmailError', errorMessage);
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
        await auth.signOut();
        return;
      }

      await handleLoginSuccess(result.user);
      
    } catch (error) {
      console.error('Login error:', error);
      setSnackbarMessage(error.message || 'An error occurred during login');
      setOpenSnackbar(true);
    } finally {
      setLoginInProgress(false);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      setLoginInProgress(true);
      setError(null);
      
      console.log('Initializing Microsoft login...');
      await initializeAuth();
      
      const loginResponse = await msalInstance.loginPopup({
        scopes: ['user.read', 'openid', 'profile', 'email']
      });
      
      const { user: firebaseUser, isNewUser } = await authenticateWithFirebase(loginResponse.accessToken);
      
      if (!firebaseUser) {
        throw new Error('Authentication failed');
      }

      // Get fresh token
      const token = await firebaseUser.getIdToken(true);
      
      // Create complete user object with all required properties
      const completeUser = {
        ...firebaseUser,
        id: firebaseUser.uid,
        accessToken: loginResponse.accessToken,
        provider: 'microsoft.com',
        microsoftAccessToken: loginResponse.accessToken,
        displayName: firebaseUser.displayName || '',
        email: firebaseUser.email || '',
        photoURL: firebaseUser.photoURL || '',
        lastLogin: new Date().toISOString()
      };

      // Update user document with all necessary fields
      const userDocRef = doc(db, 'users', firebaseUser.uid);
      await setDoc(userDocRef, {
        uid: firebaseUser.uid,
        id: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        lastLogin: serverTimestamp(),
        updatedAt: serverTimestamp(),
        provider: 'microsoft.com',
        microsoftAccessToken: loginResponse.accessToken,
        accessToken: loginResponse.accessToken,
        ...(isNewUser && { createdAt: serverTimestamp() })
      }, { merge: true });

      // Update local state with complete user info
      setUser(completeUser);

      // Log the complete user object for debugging
      console.log('Setting complete user object:', completeUser);

      navigate('/dashboard');
    } catch (error) {
      console.error('Microsoft login error:', error);
      setError('Failed to login with Microsoft. Please try again.');
    } finally {
      setLoginInProgress(false);
    }
  };

  // Update auth state listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        console.log('Auth state changed: User signed out');
        return;
      }

      if (user.email?.toLowerCase().endsWith('@gmail.com')) {
        console.log('Ignoring Gmail auth state change');
        const errorMessage = 'Personal Gmail accounts are not supported. Please use a Google Workspace account.';
        localStorage.setItem('gmailError', errorMessage);
        await auth.signOut();
        return;
      }

      try {
        await handleLoginSuccess(user);
      } catch (error) {
        console.error('Auth state error:', error);
        setError('Authentication error. Please try again.');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      // Clear local data and navigate to the login page
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Sign out error:', error);
      // Show a user-friendly error message
      setError('An error occurred during sign out. Please try again.');
    }
  };

  useEffect(() => {
    const refreshToken = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken();
          const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
          
          // If token expires in less than 5 minutes, refresh it
          if (tokenExpiration - Date.now() < 300000) {
            await currentUser.getIdToken(true);
            console.log('Token refreshed');
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
    };

    const tokenRefreshInterval = setInterval(refreshToken, 4 * 60 * 1000); // Check every 4 minutes

    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  useEffect(() => {
    const handleAuthChange = (event) => {
      const { authenticated, user } = event.detail;
      if (!authenticated) {
        // Only show error message since we don't have setOrgChartData here
        setError('Your session has expired. Please log in again.');
      }
    };
    
    window.addEventListener('authStateChange', handleAuthChange);
    
    return () => {
      window.removeEventListener('authStateChange', handleAuthChange);
    };
  }, []);

  return (
    <PageContainer>
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h2" component="h1" gutterBottom fontWeight="bold">
            Welcome Back
          </Typography>
          <Typography variant="h5" paragraph>
            Sign in to create and manage your organization charts.
          </Typography>
        </Container>
      </HeroSection>
      <LoginContainer>
        <LoginPaper>
          {isLoading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
              <CircularProgress size={60} sx={{ mb: 2 }} />
              <Typography variant="h6">One moment, please...</Typography>
              <Typography variant="body2" color="text.secondary">We're setting up your account</Typography>
            </Box>
          ) : (
            <>
              <OrgChartIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
              <Typography variant="h4" component="h2" gutterBottom>
                Sign In
              </Typography>
              <Typography variant="body1" paragraph>
                Access your account to continue using our services.
              </Typography>
              <Box sx={{ mt: 3, width: '100%' }}>
                <LoginButton onClick={handleGoogleLogin} startIcon={<GoogleLogo />} disabled={isLoading}>
                  Sign in with Google
                </LoginButton>
                <LoginButton onClick={handleMicrosoftLogin} startIcon={<MicrosoftLogo />} disabled={isLoading}>
                  Sign in with Microsoft
                </LoginButton>
              </Box>
            </>
          )}
        </LoginPaper>
      </LoginContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={null}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: 2 }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="error" 
          variant="filled"
          elevation={6}
          sx={{ 
            width: '100%',
            minWidth: '300px',
            maxWidth: '600px',
            fontSize: '1rem',
            '& .MuiAlert-message': {
              padding: '8px 0',
            }
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}

export default Login;
