import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';

function UserSearch({ data, onUserSelect, resetSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (resetSearch) {
      setSearchTerm('');
      setValue(null);
    }
  }, [resetSearch]);

  const flattenOrgChart = (node, list = []) => {
    if (!node) return list;
    list.push({ id: node.id, name: node.name, title: node.title });
    if (node.children) {
      node.children.forEach(child => flattenOrgChart(child, list));
    }
    return list;
  };

  const allUsers = data ? flattenOrgChart(data) : [];

  const handleUserSelect = (selectedUser) => {
    if (selectedUser && data) {
      const findUserSubtree = (node) => {
        if (node.id === selectedUser.id) {
          return node;
        }
        if (node.children) {
          for (let child of node.children) {
            const result = findUserSubtree(child);
            if (result) return result;
          }
        }
        return null;
      };

      const userSubtree = findUserSubtree(data);
      if (userSubtree) {
        onUserSelect(userSubtree);
      }
    }
  };

  return (
    <Box sx={{ width: 300, mb: 2 }}>
      <Autocomplete
        value={value}
        options={allUsers}
        getOptionLabel={(option) => `${option.name} (${option.title})`}
        renderInput={(params) => <TextField {...params} label="Search User" />}
        onChange={(event, newValue) => {
          setValue(newValue);
          handleUserSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={!data}
      />
    </Box>
  );
}

export default UserSearch;
