import * as XLSX from 'xlsx';

export const parseExcel = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        // Validate and transform the data to match your org chart structure
        const transformedData = jsonData.map(row => ({
          id: row.id?.toString(),
          name: row.name,
          title: row.title,
          managerId: row.managerId?.toString(),
          department: row.department,
          tags: row.tags ? JSON.parse(row.tags) : [],
          hireDate: row.hireDate,
          // Add any other fields you need
        }));

        resolve(transformedData);
      } catch (error) {
        reject(new Error('Failed to parse Excel file. Please check the format.'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read the file.'));
    };

    reader.readAsArrayBuffer(file);
  });
}; 