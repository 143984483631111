// Enhance console error handling
const originalConsoleError = console.error;
console.error = (...args) => {
  // Handle authentication errors
  if (args[0] instanceof Error) {
    const error = args[0];
    if (error.code?.includes('auth/') || error.code?.includes('functions/')) {
      console.warn('Authentication Error:', {
        code: error.code,
        message: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString()
      });
      
      // Optionally redirect to login page for specific auth errors
      if (['auth/invalid-token', 'auth/user-token-expired'].includes(error.code)) {
        window.location.href = '/login';
      }
      return;
    }
  }
  
  // Original warning suppression
  if (typeof args[0] === 'string' && args[0].includes('defaultProps')) return;
  originalConsoleError(...args);
};

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Typography } from '@mui/material';
import App from './App';
import { auth } from './authConfig.ts';
import { AuthProvider } from './contexts/AuthContext';

// Enhanced error boundary for authentication flow
class AuthErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null,
      isAuthenticated: false
    };
  }

  componentDidMount() {
    // Listen for auth state changes
    this.unsubscribe = auth.onAuthStateChanged((user) => {
      this.setState({ isAuthenticated: !!user });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Auth Error Boundary caught an error:', error, errorInfo);
    this.setState({ errorInfo });
    
    // Log error details
    const errorDetails = {
      error: {
        message: error.message,
        stack: error.stack,
        code: error.code
      },
      errorInfo,
      timestamp: new Date().toISOString(),
      url: window.location.href,
      userAgent: navigator.userAgent
    };
    
    console.error('Error Details:', errorDetails);
  }

  handleRefresh = () => {
    // Clear any stored auth state
    sessionStorage.clear();
    localStorage.clear();
    
    // Reload the page
    window.location.reload();
  };

  handleLogin = () => {
    window.location.href = '/login';
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          p={3}
          textAlign="center"
        >
          <Typography variant="h4" gutterBottom>
            Authentication Error
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            We encountered an issue with the authentication process.
            {this.state.error?.message && (
              <Box component="span" display="block" mt={1}>
                Error: {this.state.error.message}
              </Box>
            )}
          </Typography>
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRefresh}
            >
              Refresh Page
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleLogin}
            >
              Return to Login
            </Button>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Enhanced error handling for unhandled promise rejections
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Promise Rejection:', {
    reason: event.reason,
    timestamp: new Date().toISOString(),
    url: window.location.href
  });
  
  // Handle auth-related promise rejections
  if (event.reason?.code?.includes('auth/')) {
    // Redirect to login for authentication errors
    window.location.href = '/login';
  }
});

// Handle runtime errors
window.addEventListener('error', (event) => {
  console.error('Runtime Error:', {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno,
    timestamp: new Date().toISOString()
  });
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AuthErrorBoundary>
          <Elements stripe={stripePromise}>
            <CssBaseline />
            <App />
          </Elements>
        </AuthErrorBoundary>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
