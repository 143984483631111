import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  OAuthProvider, 
  User,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  getIdToken
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAKpElvEgqW1nELwBCI-0sUC4tsoeM3IaM",
  authDomain: "org-chart-generator-90761.firebaseapp.com",
  projectId: "org-chart-generator-90761",
  storageBucket: "org-chart-generator-90761.appspot.com",
  messagingSenderId: "850382136799",
  appId: "1:850382136799:web:cc491204e63f00b010506a",
  measurementId: "G-31C3CXLWT2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Force persistence setting
setPersistence(auth, browserLocalPersistence);

// Configure Microsoft provider
const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'consent',
  login_hint: 'user@firstadd.onmicrosoft.com'
});

// Initialize auth state monitor
let currentUser: User | null = null;

const initializeAuth = async () => {
  return new Promise<void>((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          currentUser = user;
          console.log('User is signed in:', user.uid);
          
          // Check token expiration
          const token = await user.getIdToken();
          const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
          console.log('Token expires at:', new Date(tokenExpiration));

          // Force token refresh if it's about to expire
          if (tokenExpiration - Date.now() < 60000) {
            console.log('Refreshing token...');
            await user.getIdToken(true);
          }
          
          window.dispatchEvent(new CustomEvent('authStateChange', {
            detail: { authenticated: true, user }
          }));
        } else {
          console.log('User is signed out');
          window.dispatchEvent(new CustomEvent('authStateChange', {
            detail: { authenticated: false, user: null }
          }));
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
      } finally {
        resolve();
      }
    });

    // Cleanup on window unload
    window.addEventListener('unload', unsubscribe);
  });
};

// Initialize services
const db = getFirestore(app);
const functions = getFunctions(app);

// Initialize auth immediately
initializeAuth().catch(console.error);

export { 
  app,
  auth, 
  db, 
  functions,
  microsoftProvider,
  initializeAuth
};