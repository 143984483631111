import axios from 'axios';
import { getStorage, ref, uploadString, getDownloadURL, uploadBytes } from "firebase/storage";
import { auth, db, functions } from '../authConfig.ts';

// Initialize storage
const storage = getStorage();

export const fetchGoogleDirectory = async (accessToken) => {
  try {
    const response = await axios.get('https://people.googleapis.com/v1/people/me/connections', {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        personFields: 'names,emailAddresses,organizations,photos,metadata,employmentInfo',
        pageSize: 1000
      }
    });

    const transformedData = response.data.connections.map(person => ({
      id: person.resourceName,
      displayName: person.names?.[0]?.displayName || 'Unknown',
      jobTitle: person.organizations?.[0]?.title || 'No Title',
      department: person.organizations?.[0]?.department || 'No Department',
      mail: person.emailAddresses?.[0]?.value || 'No Email',
      photo: person.photos?.[0]?.url || null,
      hireDate: person.employmentInfo?.[0]?.startDate || null
    }));

    return { data: transformedData };
  } catch (error) {
    console.error('Error fetching Google directory:', error);
    throw error;
  }
};

export const fetchMicrosoftDirectory = async (accessToken) => {
  try {
    const response = await axios.get('https://graph.microsoft.com/v1.0/users', {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        $select: 'id,displayName,jobTitle,department,mail,userPrincipalName,employeeHireDate',
        $expand: 'manager($levels=1)'
      }
    });

    const users = response.data.value;
    const userMap = new Map();

    for (const user of users) {
      let photoUrl = 'https://firebasestorage.googleapis.com/v0/b/org-chart-generator-90761.appspot.com/o/user.png?alt=media&token=5c84541b-f00b-4cdc-93d6-03a8c0dcea92'; // Default photo

      try {
        console.log(`Attempting to fetch photo for user ${user.id}`);
        const photoResponse = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${user.id}/photo/$value`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: 'arraybuffer'
          }
        );

        if (photoResponse.data) {
          console.log(`Photo fetched successfully for user ${user.id}`);
          const base64 = Buffer.from(photoResponse.data, 'binary').toString('base64');
          const dataUrl = `data:image/jpeg;base64,${base64}`;
          
          const uploadedUrl = await uploadPhotoToFirebase(dataUrl, user.id);
          console.log(`Photo uploaded successfully for user ${user.id}, URL:`, uploadedUrl);
          if (uploadedUrl) {
            photoUrl = uploadedUrl;
          }
        }
      } catch (photoError) {
        console.log(`Using default photo for user ${user.id}`);
      }

      // Add user to map with photo URL
      const userData = {
        id: user.id,
        name: user.displayName,
        title: user.jobTitle || 'No Title',
        department: user.department || 'No Department',
        email: user.mail || user.userPrincipalName,
        hireDate: user.employeeHireDate || null,
        photo: photoUrl,  // Make sure we're using photoUrl here
        managerId: user.manager?.id || null
      };

      console.log(`Saving user data with photo:`, userData);
      userMap.set(user.id, userData);
    }

    const result = Array.from(userMap.values());
    console.log('Final processed users:', result);
    return result;
  } catch (error) {
    console.error('Error fetching Microsoft directory:', error);
    throw new Error(`Failed to fetch directory: ${error.message}`);
  }
};

const uploadPhotoToFirebase = async (dataUrl, userId) => {
  try {
    console.log('Starting upload to Firebase for user', userId);
    
    // Create a reference to the orgchartphotos folder structure
    const storageRef = ref(storage, `orgchartphotos/${userId}/profile_photo.jpg`);
    
    // Convert data URL to blob
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    
    // Upload the file
    await uploadBytes(storageRef, blob);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);
    console.log('Photo uploaded successfully, URL:', downloadURL);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading photo for user', userId, ':', error);
    throw error;
  }
};
