import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './routes/AppRoutes';
import { ThemeProvider } from '@mui/material/styles';
import { AppWrapper } from './styles/AppStyles';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppWrapper>
          <AppRoutes />
        </AppWrapper>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;