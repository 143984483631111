import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import TableViewIcon from '@mui/icons-material/TableView';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';

// Styled components
const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '8px 24px',
  textTransform: 'none',
  fontWeight: 600,
  minWidth: '120px',
  transition: 'all 0.2s ease',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    marginTop: '8px',
    minWidth: 180,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      padding: '8px',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: '8px',
  padding: '10px 12px',
  margin: '4px 0',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '42px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '& .MuiListItemText-primary': {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

const ExportButton = ({ contentRef, fileName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportToPDF = async () => {
    try {
      setIsExporting(true);
      const content = contentRef.current;
      if (!content) return;

      // Get the content dimensions
      const contentWidth = content.scrollWidth;
      const contentHeight = content.scrollHeight;

      // Define standard page sizes (in pixels at 96 DPI)
      const A4_WIDTH = 794;  // 8.27 inches * 96 DPI
      const A4_HEIGHT = 1123; // 11.69 inches * 96 DPI

      // Calculate scale to fit width while maintaining aspect ratio
      const scale = Math.min(A4_WIDTH / contentWidth, A4_HEIGHT / contentHeight);

      // Create canvas with adjusted dimensions
      const canvas = await html2canvas(content, {
        scale: scale * 2, // Multiply by 2 for better resolution
        logging: false,
        useCORS: true,
        ignoreElements: (element) => 
          element.classList.contains('ignore-export') ||
          element.classList.contains('zoom-icon') ||
          element.classList.contains('reload-icon'),
        windowWidth: contentWidth,
        windowHeight: contentHeight
      });

      // Calculate PDF dimensions
      const imgWidth = A4_WIDTH;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Create PDF
      const pdf = new jsPDF({
        orientation: imgHeight > imgWidth ? 'portrait' : 'landscape',
        unit: 'px',
        format: [imgWidth, imgHeight]
      });

      // Add image to PDF
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');

      // Save PDF
      pdf.save(`${fileName || 'org-chart'}.pdf`);
    } catch (error) {
      console.error('Error exporting to PDF:', error);
      // Optionally show error message to user
    } finally {
      setIsExporting(false);
      handleClose();
    }
  };

  const exportToImage = async () => {
    if (!contentRef.current) {
      console.error('Content ref is not available');
      return;
    }

    const content = contentRef.current;
    const canvas = await html2canvas(content, {
      scale: 2,
      logging: false,
      useCORS: true,
      ignoreElements: (element) => element.classList.contains('ignore-export')
    });

    const imgData = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imgData;
    link.download = `${fileName || 'org-chart'}.png`;
    link.click();

    handleClose();
  };

  const exportToExcel = () => {
    if (!contentRef.current) {
      console.error('Content ref is not available');
      return;
    }

    // Extract data from DOM elements
    const nodes = contentRef.current.querySelectorAll('[data-node]');
    const flatData = Array.from(nodes).map(node => {
      // Assuming each node has a data-node attribute containing JSON data
      try {
        return JSON.parse(node.getAttribute('data-node'));
      } catch (e) {
        console.error('Error parsing node data:', e);
        return {};
      }
    });

    // Convert to worksheet
    const ws = XLSX.utils.json_to_sheet(flatData);
    
    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Organization Chart");
    
    // Save file
    XLSX.writeFile(wb, `${fileName || 'org-chart'}.xlsx`);
    handleClose();
  };

  return (
    <>
      <ActionButton
        startIcon={<DownloadIcon />}
        onClick={handleClick}
      >
        Export
      </ActionButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={exportToPDF}>
          <ListItemIcon>
            <PictureAsPdfIcon color="error" />
          </ListItemIcon>
          <ListItemText>Export as PDF</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={exportToImage}>
          <ListItemIcon>
            <ImageIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Export as Image</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={exportToExcel}>
          <ListItemIcon>
            <TableViewIcon color="success" />
          </ListItemIcon>
          <ListItemText>Export as Excel</ListItemText>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default ExportButton;
