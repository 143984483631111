import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { updateProfile, linkWithCredential, OAuthProvider } from 'firebase/auth';
import { checkSubscriptionStatusByEmail } from './stripeService';
import { auth, db, functions } from '../authConfig.ts';
import { User } from 'firebase/auth';

import axios from 'axios';

interface FirebaseTokenResponse {
  firebaseToken: string;
}

// Add interfaces for the Cloud Function
interface GetFirebaseTokenRequest {
  accessToken: string;
}

interface GetFirebaseTokenResponse {
  firebaseToken: string;
  isNewUser: boolean;
}

interface AuthResult {
  user: any;
  isNewUser: boolean;
}

interface UserData {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  lastLogin: string;
  updatedAt: string;
  createdAt: string;
  provider: string;
  subscriptionStatus: string;
  microsoftUserId?: string;
}

export const createOrUpdateUser = async (userData: Partial<UserData>) => {
  try {
    if (!userData.uid) {
      throw new Error('User ID is required');
    }

    console.log('Creating/updating user with data:', userData);
    
    const userRef = doc(db, 'users', userData.uid);
    const updatedData: Partial<UserData> = {
      ...userData,
      lastLogin: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    await setDoc(userRef, updatedData, { merge: true });
    console.log('User data saved successfully');
    
    return updatedData;
  } catch (error) {
    console.error('Error creating/updating user:', error);
    throw error;
  }
};

export const authenticateWithFirebase = async (microsoftToken: string): Promise<AuthResult> => {
  try {
    console.log('Starting Firebase authentication with Microsoft token');
    
    const getFirebaseToken = httpsCallable<GetFirebaseTokenRequest, GetFirebaseTokenResponse>(
      functions, 
      'getFirebaseTokenCallable'
    );
    
    const result = await getFirebaseToken({ 
      accessToken: microsoftToken 
    });
    
    if (!result.data?.firebaseToken) {
      throw new Error('No Firebase token received');
    }

    // Sign in and wait for auth state to settle
    const userCredential = await signInWithCustomToken(auth, result.data.firebaseToken);
    
    // Force token refresh
    await userCredential.user.getIdToken(true);
    
    // Wait for auth state to fully settle
    await new Promise<void>((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user && user.uid === userCredential.user.uid) {
          unsubscribe();
          resolve();
        }
      });
      
      // Timeout after 5 seconds
      setTimeout(() => {
        unsubscribe();
        resolve();
      }, 5000);
    });

    return {
      user: userCredential.user,
      isNewUser: result.data.isNewUser
    };
  } catch (error) {
    console.error('Error in authenticateWithFirebase:', error);
    throw error;
  }
};

export const saveOrgChart = async (userId: string, orgChartData: any, applyToSubordinates: boolean = false) => {
  try {
    console.log("Current user:", auth.currentUser);
    console.log("Attempting to save org chart for userId:", userId);
    console.log("Org chart data to be saved:", orgChartData);
    console.log("Tags to be saved:", orgChartData.tags); // Add this line
    
    if (!auth.currentUser) {
      throw new Error('User not authenticated with Firebase');
    }
    if (!userId || typeof userId !== 'string') {
      throw new Error('Invalid userId');
    }
    
    let dataToSave;
    if (Array.isArray(orgChartData)) {
      dataToSave = { data: orgChartData };
    } else if (typeof orgChartData === 'object') {
      dataToSave = { data: [orgChartData] };
    } else {
      throw new Error('Invalid orgChartData format');
    }
  
  
    if (applyToSubordinates) {
      dataToSave.data = applyTagsToSubordinates(dataToSave.data);
    }
    
    console.log("Data to save:", dataToSave);
    
    await setDoc(doc(db, 'orgCharts', userId), dataToSave);
    console.log('Org chart saved successfully');
  } catch (error) {
    console.error('Error saving org chart:', error);
    if (error.code === 'permission-denied') {
      console.error('Firebase permission denied. Check your security rules and ensure the user is authenticated.');
    }
    throw error;
  }
};


const applyTagsToSubordinates = (data) => {
  const applyTags = (node) => {
    if (node.children) {
      const tagsToApply = node.tags ? node.tags.filter(tag => tag.applyToSubordinates) : [];
      node.children = node.children.map(child => {
        const childTags = child.tags || [];
        const updatedTags = [...childTags, ...tagsToApply].reduce((acc, tag) => {
          const existingTag = acc.find(t => t.name === tag.name);
          if (existingTag) {
            existingTag.applyToSubordinates = tag.applyToSubordinates;
          } else {
            acc.push({ ...tag });
          }
          return acc;
        }, []);
        return applyTags({ ...child, tags: updatedTags });
      });
    }
    return node;
  };


  return data.map(applyTags);
};

export const getOrgChart = async (userId: string) => {
  try {
    console.log("Getting org chart for userId:", userId);
    
    // Verify we have a valid user
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("No authenticated user found");
      return null;
    }

    if (!userId || typeof userId !== 'string') {
      console.error("Invalid userId:", userId);
      return null;
    }

    // Get fresh token to ensure permissions
    await currentUser.getIdToken(true);
    
    const docSnap = await getDoc(doc(db, 'orgCharts', userId));
    if (docSnap.exists()) {
      console.log("Org chart data found in Firestore");
      return docSnap.data();
    }
    
    console.log("No org chart data found in Firestore");
    return null;
  } catch (error) {
    console.error('Error getting org chart:', error);
    // Return null instead of throwing to handle the error gracefully
    return null;
  }
};

export const saveUserData = async (uid: string, userData: any) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    await setDoc(userDocRef, {
      ...userData,
      email: auth.currentUser?.email,  // Add email for permission checking
      lastUpdated: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error;
  }
};

export const updateUserSubscription = async (userId, subscriptionStatus) => {
  try {
    await setDoc(doc(db, 'users', userId), { subscriptionStatus }, { merge: true });
    console.log('User subscription status updated successfully');
  } catch (error) {
    console.error('Error updating user subscription status:', error);
    throw error;
  }
};

export const fetchOrgChartData = async (userId: string) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No authenticated user');
    }

    // Force token refresh
    await currentUser.getIdToken(true);
    
    const docRef = doc(db, 'orgCharts', userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return null;
  } catch (error) {
    console.error('Error fetching org chart:', error);
    throw error;
  }
};

export const getUserSubscriptionStatus = async (userId: string): Promise<string> => {
  try {
    if (!userId) {
      console.error('Invalid userId:', userId);
      return 'inactive';
    }

    // Wait briefly to ensure auth is settled
    await new Promise(resolve => setTimeout(resolve, 1000));

    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log('User data from Firestore:', userData);
      return userData.subscriptionStatus || 'inactive';
    }
    
    console.log('No user document found for userId:', userId);
    return 'inactive';
  } catch (error) {
    console.error('Error getting user subscription status:', error);
    return 'inactive';
  }
};

export const getUserData = async (uid: string) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.warn('getUserData: No authenticated user found');
      return null;
    }

    console.log('getUserData: Fetching data for uid:', uid);
    
    // Get user document reference
    const userDocRef = doc(db, 'users', uid);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const data = { 
        id: docSnap.id, 
        ...docSnap.data(),
        // Ensure critical fields are present
        uid: uid,
        email: currentUser.email,
        displayName: currentUser.displayName,
        provider: currentUser.providerData[0]?.providerId || 'unknown'
      };
      return data;
    }

    // If no document exists, create one with basic user info
    const userData = {
      uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      provider: currentUser.providerData[0]?.providerId || 'unknown',
      subscriptionStatus: 'inactive'
    };
    
    console.log('getUserData: Creating new user document:', userData);
    await setDoc(userDocRef, userData);
    
    return { id: uid, ...userData };
  } catch (error) {
    console.error('Error in getUserData:', error);
    // Return null instead of empty object to indicate error
    return null;
  }
};

export const handleLoginSuccess = async (user: any) => {
  try {
    // Save initial user data
    await saveUserData(user.uid, {
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      provider: 'microsoft.com',
      createdAt: new Date().toISOString()
    });
    
    // Then fetch user data
    return await getUserData(user.uid);
  } catch (error) {
    console.error('Error handling login success:', error);
    throw error;
  }
};
