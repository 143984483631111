import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

let stripePromise: Promise<Stripe | null>;
const getStripe = (): Promise<Stripe | null> => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
  }
  return stripePromise;
};

const functions = getFunctions();
const auth = getAuth();
let analytics: ReturnType<typeof getAnalytics> | null = null;

// Uncomment this line for local development with Firebase emulator
// connectFunctionsEmulator(functions, "localhost", 5001);

export const initializeAnalytics = async (): Promise<void> => {
  if (await isSupported()) {
    analytics = getAnalytics();
  }
};

const safeLogEvent = (eventName: string, eventParams: Record<string, any>): void => {
  if (analytics) {
    logEvent(analytics, eventName, eventParams);
  } else {
    console.log('Analytics not available:', eventName, eventParams);
  }
};

export const createCheckoutSession = async (priceId: string): Promise<string> => {
  console.log("createCheckoutSession called with priceId:", priceId);
  
  const user = auth.currentUser;
  console.log("Current user:", user ? user.uid : "No user");
  
  if (!user) {
    console.error("User is not authenticated");
    throw new Error("User is not authenticated");
  }

  try {
    console.log("Calling createCheckoutSession cloud function");
    const createCheckoutSessionFunction = httpsCallable<{ priceId: string }, { sessionId: string }>(functions, 'createCheckoutSession');
    
    console.log("Sending request to cloud function");
    const result = await createCheckoutSessionFunction({ priceId });
    
    console.log("Received response from cloud function:", result);
    if (result.data && result.data.sessionId) {
      console.log("Checkout session created successfully");
      return result.data.sessionId;
    } else {
      throw new Error("Invalid response from server");
    }
  } catch (error: any) {
    console.error("Error creating checkout session:", error);
    if (error.message.includes("Cannot read properties of undefined (reading 'url')")) {
      console.error("App URL configuration is missing on the server");
      throw new Error("Server configuration error. Please contact support.");
    } else if (error.code === 'unauthenticated') {
      throw new Error("Authentication failed. Please log in again.");
    } else {
      throw new Error(error.message || "An error occurred while creating the checkout session");
    }
  }
};

export const createCustomerPortalSession = async (userId: string): Promise<string | null> => {
  try {
    console.log(`Preparing to create customer portal session for user: ${userId}`);
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (!userDoc.exists()) {
      console.error(`User document not found for userId: ${userId}`);
      return null;
    }

    const userData = userDoc.data();
    const stripeCustomerId = userData.stripeCustomerId;

    if (!stripeCustomerId) {
      console.error(`Stripe Customer ID not found for userId: ${userId}`);
      return null;
    }

    console.log(`Retrieved Stripe Customer ID: ${stripeCustomerId} for userId: ${userId}`);

    const functions = getFunctions();
    const createPortalSession = httpsCallable(functions, 'createStripeCustomerPortalSession');
    
    console.log(`Calling createStripeCustomerPortalSession function with customerId: ${stripeCustomerId}`);
    const result = await createPortalSession({ stripeCustomerId });
    
    if (result.data) {
      console.log(`Customer portal session created successfully for Stripe Customer ID: ${stripeCustomerId}`);
      return result.data as string;
    } else {
      console.error(`No portal URL returned for Stripe Customer ID: ${stripeCustomerId}`);
      return null;
    }
  } catch (error) {
    console.error(`Error creating Stripe Customer Portal session for userId: ${userId}:`, error);
    return null;
  }
};

export const checkAndUpdateStripeCustomer = async (userId: string, email: string): Promise<void> => {
  try {
    if (!userId || !email) {
      throw new Error(`Invalid input: userId=${userId}, email=${email}`);
    }
    console.log('Calling checkAndUpdateStripeCustomerCallable with userId:', userId, 'and email:', email);
    const checkAndUpdateStripeCustomerFunction = httpsCallable<{ userId: string, email: string }, { success: boolean }>(functions, 'checkAndUpdateStripeCustomerCallable');
    const result = await checkAndUpdateStripeCustomerFunction({ userId, email });
    if (result.data.success) {
      console.log('Stripe customer information checked and updated');
    } else {
      throw new Error('Failed to update Stripe customer information');
    }
  } catch (error) {
    console.error('Error checking and updating Stripe customer:', error);
    throw error;
  }
};

export const checkSubscriptionStatusByEmail = async (email: string): Promise<{
  status: string;
  customerId: string | null;
  plan?: string;
  nextBillingDate?: string;
  period?: string;
}> => {
  try {
    console.log('Checking subscription status for email:', email);
    if (!email) {
      throw new Error('Invalid email');
    }
    const checkSubscriptionStatusFunction = httpsCallable<
      { email: string },
      {
        status: string;
        customerId: string | null;
        plan?: string;
        nextBillingDate?: string;
        period?: string;
      }
    >(functions, 'checkSubscriptionStatusByEmail');
    
    const result = await checkSubscriptionStatusFunction({ email });
    console.log('Subscription status result from Stripe:', result.data);
    return result.data;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    if (error instanceof Error) {
      throw new Error(`Error checking subscription status: ${error.message}`);
    } else {
      throw new Error('Unknown error checking subscription status');
    }
  }
};
