import { getAnalytics, logEvent, Analytics } from 'firebase/analytics';
import { app } from '../authConfig.ts';

const analytics: Analytics = getAnalytics(app);

export const logCrash = (error: Error) => {
  logEvent(analytics, 'app_crash', {
    description: error.message,
    stack: error.stack,
    name: error.name,
    fatal: true,
  });
};

export const logError = (error: Error) => {
  logEvent(analytics, 'app_error', {
    description: error.message,
    stack: error.stack,
    name: error.name,
    fatal: false,
  });
};
