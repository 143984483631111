import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  TextField,
  Button,
  Box,
  Avatar,
  Chip,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PASTEL_COLORS = [
  '#FFB3BA', '#BAFFC9', '#BAE1FF', '#FFFFBA', '#FFDFBA', '#E0BBE4',
  '#D4F0F0', '#FFC6FF', '#DAEAF6', '#FFE5B4', '#C1E1C1', '#FFF0F5'
];

function EditPersonModal({ person, onClose, onSave, allUsers }) {
  const [editedPerson, setEditedPerson] = useState({
    id: person.id || '',
    name: person.name || '',
    title: person.title || '',
    department: person.department || '',
    hireDate: person.hireDate || '',
    photo: person.photo || '',
    managerId: person.managerId || ''
  });
  const [newPhoto, setNewPhoto] = useState(null);
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState(person.tags || []);
  const [manager, setManager] = useState(person.managerId || '');

  useEffect(() => {
    setEditedPerson({
      id: person.id || '',
      name: person.name || '',
      title: person.title || '',
      department: person.department || '',
      hireDate: person.hireDate || '',
      photo: person.photo || '',
      managerId: person.managerId || ''
    });
    setTags(person.tags || []);
    setManager(person.managerId || '');
  }, [person]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPerson((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddTag = () => {
    if (newTag.trim()) {
      setTags([...tags, { 
        name: newTag.trim(), 
        applyToSubordinates: false,
        color: PASTEL_COLORS[Math.floor(Math.random() * PASTEL_COLORS.length)],
      }]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleTagApplyChange = (index) => {
    setTags(tags.map((tag, i) => 
      i === index ? { ...tag, applyToSubordinates: !tag.applyToSubordinates } : tag
    ));
  };

  const handleManagerChange = (event, newValue) => {
    setManager(newValue ? newValue.id : '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...editedPerson, photo: newPhoto || editedPerson.photo, tags: tags, managerId: manager });
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Typography 
          variant="h4" 
          component="h2" 
          sx={{
            fontSize: '1.75rem',
            fontWeight: 600,
            color: '#1a1a1a',
            mb: 4,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: 0,
              width: '40px',
              height: '3px',
              backgroundColor: 'primary.main',
              borderRadius: '2px',
            }
          }}
        >
          {editedPerson.name}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              src={newPhoto || editedPerson.photo}
              sx={{ width: 100, height: 100, mr: 2 }}
            />
            <input
              accept="image/*"
              type="file"
              onChange={handlePhotoChange}
              style={{ display: 'none' }}
              id="photo-upload"
            />
            <label htmlFor="photo-upload">
              <Button variant="contained" component="span">
                Upload Photo
              </Button>
            </label>
          </Box>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={editedPerson.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Title"
            name="title"
            value={editedPerson.title}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Department"
            name="department"
            value={editedPerson.department}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Hire Date"
            name="hireDate"
            type="date"
            value={editedPerson.hireDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Autocomplete
            value={allUsers.find(user => user.id === manager) || null}
            onChange={handleManagerChange}
            options={allUsers.filter(user => user.id !== person.id)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Manager" fullWidth margin="normal" />}
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Tags
            </Typography>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <TextField
                fullWidth
                size="small"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                placeholder="Add a new tag"
              />
              <IconButton onClick={handleAddTag} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
            {tags.map((tag, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Chip
                  label={tag.name}
                  onDelete={() => handleRemoveTag(index)}
                  sx={{ mr: 1, backgroundColor: tag.color }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tag.applyToSubordinates}
                      onChange={() => handleTagApplyChange(index)}
                      size="small"
                    />
                  }
                  label="Apply to subordinates"
                />
              </Box>
            ))}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

EditPersonModal.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    photo: PropTypes.string,
    department: PropTypes.string,
    hireDate: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      applyToSubordinates: PropTypes.bool.isRequired,
      color: PropTypes.string,
    })),
    managerId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default EditPersonModal;
