const FREE_PLAN_LIMITS = {
  maxEmployees: 15,
  exportFormats: ['PNG'],
  displayFields: ['name', 'title'],
};

const PREMIUM_FEATURES = {
  maxEmployees: Infinity,
  exportFormats: ['PNG', 'PDF', 'SVG'],
  displayFields: ['name', 'title', 'department', 'hireDate'],
  photoUpload: true,
  customTags: true,
  advancedSearch: true,
  multiDepartment: true,
};

// Cache premium status checks
const premiumStatusCache = new Map();

export const checkFeatureAccess = (user, feature) => {
  const isPremium = user?.subscriptionStatus === 'active';
  return isPremium ? PREMIUM_FEATURES[feature] : FREE_PLAN_LIMITS[feature];
};

export const isFeatureEnabled = (user, feature) => {
  if (!user?.uid) return false;
  
  if (feature === 'premium') {
    const cacheKey = `${user.uid}-${feature}`;
    
    // Check cache first
    if (premiumStatusCache.has(cacheKey)) {
      return premiumStatusCache.get(cacheKey);
    }
    
    const isPremium = user.premium === true && user.subscriptionStatus === 'active';
    
    // Only log on development and cache misses
    if (process.env.NODE_ENV === 'development') {
      console.log('Premium status calculated:', {
        uid: user.uid,
        isPremium,
        cached: false
      });
    }
    
    // Cache the result
    premiumStatusCache.set(cacheKey, isPremium);
    return isPremium;
  }
  
  return false;
};

// Clear cache when needed (e.g., on logout)
export const clearFeatureCache = () => {
  premiumStatusCache.clear();
}; 