import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, microsoftProvider } from '../authConfig.ts';
import { onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../authConfig.ts';
import { logError } from '../services/crashlyticsService.ts';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    let mounted = true;
    
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!mounted) return;
      
      try {
        if (firebaseUser) {
          // Force token refresh
          await firebaseUser.getIdToken(true);
          
          // Set up a timer to periodically refresh the token
          const refreshToken = async () => {
            if (firebaseUser) {
              await firebaseUser.getIdToken(true);
              console.log('Token refreshed');
            }
          };
          
          const tokenRefreshInterval = setInterval(refreshToken, 30 * 60 * 1000); // Refresh every 30 minutes
          
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          const userData = userDoc.exists() ? userDoc.data() : null;
          
          if (mounted) {
            setUser({
              ...firebaseUser,
              ...userData,
            });
            setLoading(false);
          }
          
          // Clean up the interval on unmount
          return () => {
            clearInterval(tokenRefreshInterval);
          };
        } else {
          if (mounted) {
            setUser(null);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Auth state error:', error);
        if (mounted) {
          setAuthError(error.message);
          setLoading(false);
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  // Add sign in method
  const signIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, microsoftProvider);
      return result.user;
    } catch (error) {
      logError(error);
      setAuthError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    authError,
    signIn,
    setUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 