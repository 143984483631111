import React, { useState, useRef, useEffect, forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import OrgChart from '@dabeng/react-orgchart';
import EditPersonModal from './EditPersonModal';
import { Box, Typography, Card, CardContent, IconButton, Button, CircularProgress, Alert, Chip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchMicrosoftDirectory, fetchGoogleDirectory } from '../services/directoryService';
import { 
  saveOrgChart, 
  fetchOrgChartData, 
  getOrgChart,
} from '../services/databaseService.ts';
import { isTokenExpired, refreshAccessToken } from '../services/authService';
import './OrgChartComponent.css';
import ExportButton from './ExportButton';
import * as Icons from '@mui/icons-material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonIcon from '@mui/icons-material/Person';
import { httpsCallable } from 'firebase/functions';
import { functions, auth, db } from '../authConfig.ts';
import { getUserData } from '../services/databaseService.ts';
import { msalInstance } from '../msalConfig';
import { logError } from '../services/crashlyticsService.ts';
import { styled } from '@mui/system';
import UserSearch from './UserSearch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { checkFeatureAccess, isFeatureEnabled } from '../utils/featureFlags';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { parseExcel } from '../utils/excelParser';
import DownloadIcon from '@mui/icons-material/Download';
import { getDoc, doc } from 'firebase/firestore';

const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
}));

const ControlsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 2,
  gap: 2,
  position: 'relative',
});

const ZoomControls = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  gap: 1,
  zIndex: 1,
});

const ChartContainer = styled('div')({
  width: '100%',
  minHeight: '500px',
  position: 'relative',
  overflow: 'hidden',
  padding: '20px',
  '@media print': {
    width: '100%',
    height: 'auto',
    overflow: 'visible'
  }
});

const OrgChartComponent = React.memo(forwardRef(({ user, setUser, displayFields, setOrgChartData, orgChartData }, ref) => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const chartRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [key, setKey] = useState(0);
  const [isUserLoading, setIsUserLoading] = useState(!user || !user.id);
  const [isDataWiped, setIsDataWiped] = useState(false);
  const [currentSubtree, setCurrentSubtree] = useState(orgChartData);
  const [resetSearch, setResetSearch] = useState(false);
  const [showRefreshWarning, setShowRefreshWarning] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const fileInputRef = useRef(null);
  const [isConfirming, setIsConfirming] = useState(false);

  // Add logging for initial props
  useEffect(() => {
    console.log('OrgChartComponent mounted with props:', {
      user,
      displayFields,
      orgChartData,
      isLoading,
      isUserLoading,
      error
    });
  }, []);

  // Add logging for data loading
  useEffect(() => {
    console.log('Data loading state changed:', {
      isLoading,
      isUserLoading,
      error,
      hasOrgChartData: !!orgChartData
    });
  }, [isLoading, isUserLoading, error, orgChartData]);

  useEffect(() => {
    console.log('displayFields changed:', displayFields);
    // Force a re-render of the entire component
    setKey(prevKey => prevKey + 1);
  }, [displayFields]);

  useEffect(() => {
    // Reset to top level when component mounts or orgChartData changes
    setCurrentSubtree(orgChartData);
    setResetSearch(true);
  }, [orgChartData]);

  useEffect(() => {
    if (orgChartData) {
      const flattenUsers = (node, users = []) => {
        users.push({ id: node.id, name: node.name });
        if (node.children) {
          node.children.forEach(child => flattenUsers(child, users));
        }
        return users;
      };
      setAllUsers(flattenUsers(orgChartData));
    }
  }, [orgChartData]);

  const loadOrgChartData = async () => {
    console.log("Starting loadOrgChartData with user:", {
      uid: user?.uid,
      id: user?.id,
      provider: user?.provider,
      hasAccessToken: !!user?.accessToken,
      hasMicrosoftToken: !!user?.microsoftAccessToken
    });

    try {
      if (!user?.uid || !user?.provider) {
        throw new Error('Missing required user information');
      }

      setIsLoading(true);
      setError(null);

      // First try to get saved data
      const savedData = await fetchOrgChartData(user.uid);
      
      if (savedData) {
        console.log("Found saved org chart data");
        const preparedData = prepareOrgChartData(savedData);
        setOrgChartData(preparedData);
        return;
      }

      // Get the appropriate access token based on provider
      let accessToken;
      if (user.provider === 'microsoft.com') {
        if (!user.microsoftAccessToken) {
          throw new Error('Microsoft access token not found');
        }
        accessToken = user.microsoftAccessToken;
      } else if (user.provider === 'google.com') {
        if (!user.accessToken) {
          throw new Error('Google access token not found');
        }
        accessToken = user.accessToken;
      } else {
        throw new Error(`Unsupported provider: ${user.provider}`);
      }

      // Fetch directory data based on provider
      let directoryData;
      try {
        if (user.provider === 'microsoft.com') {
          directoryData = await fetchMicrosoftDirectory(accessToken);
        } else if (user.provider === 'google.com') {
          directoryData = await fetchGoogleDirectory(accessToken);
        }
      } catch (fetchError) {
        console.error('Error fetching directory data:', fetchError);
        throw new Error(`Failed to fetch directory data: ${fetchError.message}`);
      }

      if (!directoryData || directoryData.error) {
        throw new Error(directoryData?.message || 'Failed to fetch directory data');
      }

      // Save and prepare the data
      try {
        await saveOrgChart(user.uid, directoryData);
        const preparedData = prepareOrgChartData(directoryData);
        setOrgChartData(preparedData);
        setError(null); // Clear any existing errors
      } catch (saveError) {
        console.error('Error saving org chart data:', saveError);
        throw new Error(`Failed to save org chart data: ${saveError.message}`);
      }
    } catch (error) {
      console.error("Error in loadOrgChartData:", error);
      setError(error.message || 'Failed to load organization data');
      setOrgChartData(null); // Clear any partial data
    } finally {
      setIsLoading(false);
    }
  };


  const prepareOrgChartData = useMemo(() => {
    return (data) => {
      if (!data || !Array.isArray(data)) {
        console.error("Invalid data structure:", data);
        return null;
      }

      const buildHierarchy = (items) => {
        const itemMap = new Map();

        // First pass: create all nodes
        items.forEach(item => {
          itemMap.set(item.id, {
            ...item,
            children: item.children || []
          });
        });


        // Second pass: establish hierarchy
        items.forEach(item => {
          const node = itemMap.get(item.id);
          if (item.managerId && itemMap.has(item.managerId)) {
            const parent = itemMap.get(item.managerId);
            if (!parent.children) parent.children = [];
            parent.children.push(node);
          }
        });

        // Find the root nodes (nodes without a manager)
        const rootNodes = Array.from(itemMap.values()).filter(node => !node.managerId);

        console.log("Root nodes:", rootNodes);

        if (rootNodes.length === 1) {
          return rootNodes[0];
        } else if (rootNodes.length > 1) {
          return {
            id: "root",
            name: "Organization",
            title: "",
            children: rootNodes
          };
        } else {
          console.error("No root nodes found in the data");
          return null;
        }
      };

      return buildHierarchy(data);
    };
  }, []); // Empty dependency array, as it doesn't depend on any external values


  const handleRefreshData = async () => {
    console.log('Starting handleRefreshData');
    try {
      setIsLoading(true);
      setError(null);

      let currentAccessToken = user.accessToken;
      console.log('Token status:', {
        hasToken: !!currentAccessToken,
        provider: user.provider,
        isExpired: currentAccessToken ? isTokenExpired(currentAccessToken) : true
      });

      if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
        console.log('Refreshing access token...');
        currentAccessToken = await refreshAccessToken(user);
        setUser(prevUser => ({ ...prevUser, accessToken: currentAccessToken }));
      }

      if (!currentAccessToken) {
        throw new Error('Failed to obtain valid access token');
      }

      let directoryData;
      console.log(`Fetching directory data for provider: ${user.provider}`);
      
      if (user.provider === 'microsoft.com') {
        directoryData = await fetchMicrosoftDirectory(currentAccessToken);
      } else if (user.provider === 'google.com') {
        directoryData = await fetchGoogleDirectory(currentAccessToken);
      } else {
        throw new Error(`Unsupported provider: ${user.provider}`);
      }

      console.log('Directory data fetched:', {
        hasData: !!directoryData,
        error: directoryData?.error
      });

      if (directoryData?.error) {
        throw new Error(directoryData.message);
      }

      if (directoryData) {
        await saveOrgChart(user.uid, directoryData);
        const preparedData = prepareOrgChartData(directoryData);
        setOrgChartData(preparedData);
        
        // Clear datawipe flag
        const clearDataWipeFunction = httpsCallable(functions, 'clearDataWipeFlag');
        await clearDataWipeFunction({ userId: user.uid });
        setIsDataWiped(false);
        setUser(prevUser => ({ ...prevUser, datawipe: false }));
      } else {
        throw new Error('No directory data received');
      }
    } catch (error) {
      console.error('Error in handleRefreshData:', error);
      setError(error.message || 'Failed to refresh data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNodeClick = useCallback((nodeData) => {
    console.log('Node clicked:', nodeData);
  }, []);

  const handleEditClick = useCallback((e, nodeData) => {
    e.stopPropagation();
    console.log('Edit button clicked');
    console.log('Node data:', nodeData);
    setSelectedPerson(nodeData);
    setIsModalOpen(true);
    console.log('Is modal open:', true);
  }, []);

  useEffect(() => {
    console.log('Modal open state changed:', isModalOpen);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedPerson(null);
  }, []);

  const handleSavePerson = useCallback(async (updatedPerson) => {
    console.log('Saving updated person:', updatedPerson);
    try {
      const applyToSubordinates = updatedPerson.tags && updatedPerson.tags.some(tag => tag.applyToSubordinates);

      const updateNode = (node) => {
        if (node.id === updatedPerson.id) {
          return { ...node, ...updatedPerson, tags: updatedPerson.tags || [] };
        }
        if (node.children) {
          return { ...node, children: node.children.map(updateNode) };
        }
        return node;
      };

      const removeFromOldManager = (node) => {
        if (node.children) {
          node.children = node.children.filter(child => child.id !== updatedPerson.id);
          node.children = node.children.map(removeFromOldManager);
        }
        return node;
      };

      const addToNewManager = (node) => {
        if (node.id === updatedPerson.managerId) {
          if (!node.children) node.children = [];
          node.children.push(updatedPerson);
          return node;
        }
        if (node.children) {
          node.children = node.children.map(addToNewManager);
        }
        return node;
      };

      let updatedOrgChartData = removeFromOldManager(orgChartData);
      updatedOrgChartData = addToNewManager(updatedOrgChartData);
      updatedOrgChartData = updateNode(updatedOrgChartData);

      setOrgChartData(updatedOrgChartData);

      await saveOrgChart(user.id, updatedOrgChartData, applyToSubordinates);

      if (applyToSubordinates) {
        const refreshedData = await fetchOrgChartData(user.id);
        setOrgChartData(refreshedData[0]);
      }

      handleCloseModal();
    } catch (error) {
      console.error('Error saving updated person:', error);
    }
  }, [orgChartData, user.id, handleCloseModal]);

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 2));
  };


  const handleZoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5));
  };

  const handleMouseDown = (e) => {
    if (e.target.closest('button')) return; // Ignore if clicked on a button
    setIsDragging(true);
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const nodeTemplate = useMemo(() => {
    // Cache the premium status
    const isPremium = isFeatureEnabled(user, 'premium');
    
    return ({ nodeData }) => {
      return (
        <StyledCard className="org-node" variant="outlined">
          <CardContent sx={{ position: 'relative', pt: 4, textAlign: 'center' }}>
            <StyledIconButton
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(e, nodeData);
              }}
              size="small"
              sx={{ position: 'absolute', top: 5, right: 5 }}
            >
              <EditIcon fontSize="small" className="ignore-export" />
            </StyledIconButton>
            {isPremium && nodeData.photo && (
              <Box
                component="img"
                src={nodeData.photo}
                alt={`${nodeData.name}'s photo`}
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  marginBottom: '8px',
                  objectFit: 'cover',
                  display: 'inline-block',
                }}
                onError={(e) => {
                  console.error('Error loading image:', e);
                  e.target.src = 'https://firebasestorage.googleapis.com/v0/b/org-chart-generator-90761.appspot.com/o/user.png?alt=media&token=5c84541b-f00b-4cdc-93d6-03a8c0dcea92';
                }}
              />
            )}
            {displayFields.name && (
              <Typography variant="subtitle1" component="div" fontWeight="bold">
                {nodeData.name}
              </Typography>
            )}
            {displayFields.title && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5 }}>
                <PersonIcon sx={{ fontSize: 14, mr: 0.5, color: 'primary.main' }} />
                <Typography variant="body2" color="text.secondary">
                  {nodeData.title}
                </Typography>
              </Box>
            )}
            {isPremium && displayFields.department && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5 }}>
                <WorkOutlineIcon sx={{ fontSize: 14, mr: 0.5, color: 'primary.main' }} />
                <Typography variant="body2" color="text.secondary">
                  {nodeData.department}
                </Typography>
              </Box>
            )}
            {isPremium && nodeData.tags && nodeData.tags.length > 0 && (
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {nodeData.tags.map((tag, index) => (
                  <StyledChip
                    key={index}
                    label={tag.name}
                    size="small"
                  />
                ))}
              </Box>
            )}
            {isPremium && displayFields.hireDate && nodeData.hireDate && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5 }}>
                <EventNoteIcon sx={{ fontSize: 14, mr: 0.5, color: 'primary.main' }} />
                <Typography variant="body2" color="text.secondary">
                  {nodeData.hireDate}
                </Typography>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      );
    };
  }, [user, displayFields, handleEditClick]); // Premium status only recalculated when these change

  const handleUserSelect = useCallback((subtree) => {
    setCurrentSubtree(subtree);
    setResetSearch(false);
  }, []);

  const handleResetToTopLevel = useCallback(() => {
    setCurrentSubtree(orgChartData);
    setResetSearch(true);
  }, [orgChartData]);

  useEffect(() => {
    if (orgChartData) {
      const countEmployees = (node) => {
        let count = 1;
        if (node.children) {
          node.children.forEach(child => {
            count += countEmployees(child);
          });
        }
        return count;
      };

      const totalEmployees = countEmployees(orgChartData);
      const maxEmployees = checkFeatureAccess(user, 'maxEmployees');

      if (totalEmployees > maxEmployees) {
        setError(`Free plan is limited to ${maxEmployees} employees. Please upgrade to add more.`);
      }
    }
  }, [orgChartData, user]);

  const handleRefreshClick = () => {
    setShowRefreshWarning(true);
  };

  const handleRefreshConfirm = async () => {
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }
    
    setIsConfirming(false);
    setShowRefreshWarning(false);
    await handleRefreshData();
  };

  const handleRefreshCancel = () => {
    setIsConfirming(false);
    setShowRefreshWarning(false);
  };

  const handleUploadClick = () => {
    setShowUploadDialog(true);
    setUploadError(null);
  };

  const handleUploadClose = () => {
    setShowUploadDialog(false);
    setUploadError(null);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsLoading(true);
      setUploadError(null);

      const parsedData = await parseExcel(file);
      if (!parsedData || !parsedData.length) {
        throw new Error('No valid data found in the uploaded file');
      }

      // Save the uploaded data
      await saveOrgChart(user.id, parsedData);
      
      // Update the UI with new data
      const preparedData = prepareOrgChartData(parsedData);
      setOrgChartData(preparedData);
      setCurrentSubtree(preparedData);
      
      setShowUploadDialog(false);
      event.target.value = null; // Reset file input
    } catch (error) {
      console.error('Error uploading backup:', error);
      setUploadError(error.message || 'Failed to upload backup. Please check the file format.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadExcel = () => {
    // Helper function to flatten the hierarchical data
    const flattenOrgData = (node, flatData = []) => {
      const nodeData = {
        id: node.id,
        name: node.name,
        title: node.title,
        department: node.department,
        managerId: node.managerId,
        hireDate: node.hireDate,
        tags: node.tags ? JSON.stringify(node.tags) : ''
        // Add any other fields you want to include
      };
      
      flatData.push(nodeData);
      
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => flattenOrgData(child, flatData));
      }
      
      return flatData;
    };

    try {
      // Convert hierarchical data to flat array
      const flatData = flattenOrgData(orgChartData);
      
      // Create worksheet
      const worksheet = XLSX.utils.json_to_sheet(flatData);
      
      // Create workbook and append worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Org Chart");
      
      // Generate filename with current date
      const filename = `org_chart_backup_${new Date().toISOString().split('T')[0]}.xlsx`;
      
      // Write file
      XLSX.writeFile(workbook, filename);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
      // Optionally show an error message to the user
    }
  };

  useEffect(() => {
    let mounted = true;
    let authCheckTimeout;

    const handleAuthState = async (currentUser) => {
      console.log('Auth state changed:', { 
        hasCurrentUser: !!currentUser,
        mounted,
        currentUserDetails: currentUser ? {
          uid: currentUser.uid,
          email: currentUser.email,
          provider: currentUser.providerData[0]?.providerId
        } : null
      });

      if (!mounted) return;
      
      try {
        if (currentUser) {
          await currentUser.getIdToken(true);
          if (mounted) {
            setIsUserLoading(false);
            setIsLoading(false);
            setError(null);
          }
        } else {
          if (mounted) {
            setIsUserLoading(true);
            setIsLoading(false);
            setError('Please log in to continue');
          }
        }
      } catch (error) {
        console.error('Error in auth state handler:', error);
        if (mounted) {
          setIsLoading(false);
          setError('Authentication error. Please try logging in again.');
          setIsUserLoading(false);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (authCheckTimeout) clearTimeout(authCheckTimeout);
      
      authCheckTimeout = setTimeout(async () => {
        try {
          if (currentUser) {
            // Get fresh Firebase token
            const firebaseToken = await currentUser.getIdToken(true);
            
            // Get user document from Firestore
            const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
            const userData = userDoc.data();
            
            // Create complete user object with all necessary fields
            const completeUser = {
              ...currentUser,
              ...userData, // Spread all Firestore data first
              id: currentUser.uid,
              uid: currentUser.uid,
              accessToken: firebaseToken,
              premium: userData?.premium === true,
              subscriptionStatus: userData?.subscriptionStatus || null
            };

            // Add provider-specific properties
            if (userData?.microsoftAccessToken) {
              completeUser.microsoftAccessToken = userData.microsoftAccessToken;
              completeUser.provider = 'microsoft.com';
            } else if (userData?.googleAccessToken) {
              completeUser.accessToken = userData.googleAccessToken;
              completeUser.provider = 'google.com';
            } else {
              throw new Error('No valid access token found');
            }
            
            console.log('Setting complete user with premium status:', {
              premium: completeUser.premium,
              status: completeUser.subscriptionStatus
            });
            
            setUser(completeUser);
            setIsUserLoading(false);
            setIsLoading(false);
            setError(null);
            
            // Trigger data load if needed
            if (!orgChartData) {
              loadOrgChartData();
            }
          } else {
            setIsUserLoading(true);
            setError('Please log in to continue');
          }
        } catch (error) {
          console.error('Error in auth state handler:', error);
          setError('Authentication error. Please try logging in again.');
          setIsUserLoading(false);
        }
      }, 500);
    });

    return () => {
      mounted = false;
      if (authCheckTimeout) clearTimeout(authCheckTimeout);
      unsubscribe();
    };
  }, []);

  const checkAuth = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No authenticated user');
    }
    
    // Check token expiration
    const token = await currentUser.getIdToken();
    const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
    
    if (tokenExpiration - Date.now() < 60000) {
      // Token is about to expire, force refresh
      await currentUser.getIdToken(true);
    }
    
    return token;
  };

  useEffect(() => {
    const handleAuthChange = (event) => {
      const { authenticated, user } = event.detail;
      if (!authenticated) {
        // Clear local data and show a user-friendly message
        setOrgChartData(null);
        setError('Your session has expired. Please log in again.');
      }
    };
    
    window.addEventListener('authStateChange', handleAuthChange);
    
    return () => {
      window.removeEventListener('authStateChange', handleAuthChange);
    };
  }, []);

  const fetchOrgChartData = async (userId) => {
    console.log('Fetching org chart data...');
    try {
      const docRef = doc(db, 'orgCharts', userId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return docSnap.data().data;
      }
      return null;
    } catch (error) {
      console.error('Error fetching org chart data:', error);
      throw error;
    }
  };


  useEffect(() => {
    const loadUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          const userData = userDoc.data();
          console.log('Loading user data from Firebase:', userData);

          // Create a new plain object with the merged data
          const updatedUser = {
            // Start with a clean object
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            displayName: auth.currentUser.displayName,
            // Add all Firebase custom fields
            ...userData,
            // Ensure these specific fields are set
            premium: userData?.premium === true,
            subscriptionStatus: userData?.subscriptionStatus || null,
          };

          console.log('Merged user object:', updatedUser);
          setUser(updatedUser);
          
        } catch (error) {
          console.error('Error loading user data:', error);
        }
      }
    };
    
    loadUserData();
  }, []);

  if (isUserLoading) {
    return <CircularProgress />;
  }

  return (
    <ChartContainer ref={ref}>
      <ControlsContainer>
        <Box sx={{ flexGrow: 1, maxWidth: '300px' }} className="ignore-export">
          <UserSearch 
            data={orgChartData} 
            onUserSelect={handleUserSelect} 
            resetSearch={resetSearch}
          />
        </Box>
        <ZoomControls>
          <StyledIconButton onClick={handleZoomIn}>
            <ZoomInIcon className="zoom-icon" />
          </StyledIconButton>
          <StyledIconButton onClick={handleZoomOut}>
            <ZoomOutIcon className="zoom-icon" />
          </StyledIconButton>
          <StyledIconButton onClick={handleRefreshClick}>
            <RefreshIcon className="reload-icon" />
          </StyledIconButton>
          <StyledIconButton onClick={handleResetToTopLevel} className="ignore-export">
            <FullscreenIcon className="fullscreen-icon" />
          </StyledIconButton>
        </ZoomControls>
      </ControlsContainer>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : isDataWiped ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            All data has been deleted
          </Typography>
          <Typography variant="body1" paragraph>
            You have deleted all your organization data. Refreshing the org chart will re-add the data from your directory.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRefreshData}>
            Refresh Org Chart
          </Button>
        </Box>
      ) : currentSubtree ? (
        <>
          <div
            ref={chartRef}
            key={key}
            style={{
              transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
              transformOrigin: '0 0',
              cursor: isDragging ? 'grabbing' : 'grab',
              minHeight: '100%',
              minWidth: '100%',
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <OrgChart
              datasource={currentSubtree}
              chartClass="myChart"
              NodeTemplate={nodeTemplate}
              pan={true}
              zoom={true}
              zoominLimit={2}
              zoomoutLimit={0.5}
            />
          </div>
          <Dialog 
            open={showRefreshWarning} 
            onClose={handleRefreshCancel}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle sx={{ pb: 1 }}>
              <Typography variant="h6" component="div" fontWeight="bold">
                Refresh Organization Data
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Alert severity="warning" sx={{ mb: 2 }}>
                This action will overwrite your current organization data with the latest
                information from your directory.
              </Alert>
              <Typography variant="body1" sx={{ mb: 2 }}>
                To preserve any custom changes you've made:
              </Typography>
              <Box component="ol" sx={{ 
                pl: 4,
                '& li': {
                  mb: 1,
                  color: 'text.primary',
                  fontSize: '0.875rem',
                }
              }}>
                <li>Download a backup of your current data</li>
                <li>Proceed with the refresh</li>
                <li>Use the "Upload Backup" feature to restore if needed</li>
              </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button 
                onClick={handleDownloadExcel}
                startIcon={<DownloadIcon />}
                variant="outlined"
                color="primary"
              >
                Download Backup
              </Button>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button 
                  onClick={handleRefreshCancel} 
                  color="error"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button 
                  onClick={handleRefreshConfirm} 
                  color="primary" 
                  variant="contained"
                  startIcon={!isConfirming && <RefreshIcon />}
                >
                  {isConfirming ? 'CONFIRM' : 'Refresh Data'}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          <Dialog open={showUploadDialog} onClose={handleUploadClose}>
            <DialogTitle>Upload Backup</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Upload a previously exported Excel backup file to restore your org chart data.
                This will overwrite your current data.
              </DialogContentText>
              {uploadError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {uploadError}
                </Alert>
              )}
              <input
                type="file"
                ref={fileInputRef}
                accept=".xlsx,.xls"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => fileInputRef.current.click()}
                  startIcon={<UploadFileIcon />}
                >
                  Select File
                </Button>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUploadClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Typography>No organization chart data available.</Typography>
      )}
      {isModalOpen && (
        <EditPersonModal
          person={selectedPerson}
          onClose={handleCloseModal}
          onSave={handleSavePerson}
          allUsers={allUsers}
        />
      )}
    </ChartContainer>
  );
})); // Add this closing parenthesis

OrgChartComponent.displayName = 'OrgChartComponent';

OrgChartComponent.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  displayFields: PropTypes.object.isRequired,
  setOrgChartData: PropTypes.func.isRequired,
  orgChartData: PropTypes.object,
};

export default OrgChartComponent;
